import React from 'react'

import './ButtonNext.css'

const rightArrow = require('../../images/forward-white.svg');

const ButtonNext = (props) => {
    return (
        <button className={"btn next " + (props.className ? props.className : '')}
            type={props.type ? props.type : 'button'}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}
            onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
            <img alt="Go next button" src={rightArrow} />
        </button>
    );
}

export default ButtonNext;