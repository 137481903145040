import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom'


import '../Hamburger/HamburgerAlternate.scss';
import '../Hamburger/hamburgers.css';
import '../Hamburger/Hamburger.css';


import Cursor from '../../../node_modules/cuberto-cursor/src/js/cursor';
import Magnetic from '../../../node_modules/cuberto-cursor/src/js/magnetic';

import { findDOMNode } from 'react-dom';
import $, { timers } from 'jquery';

import {
    ScrollTrigger,
    Draggable,
    MotionPathPlugin,
} from "gsap/all";



var gsap;
var detect = require('is-client');
if (detect()) {
    gsap = require('gsap');
    gsap.gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);
}

class Burger extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hamburgerActive: false,
            loadingCount: 1,
            loadingActive: false,
            timeLineMenu: null,
        };
        this.handleOnScroll = this.handleOnScroll.bind(this);
    }


;    

    toggleClass = () => {
        const { hamburgerActive, loadingCount } = this.state;

        this.setState({
            hamburgerActive: !hamburgerActive,
            loadingCount: loadingCount + 1
        });
    };



    componentDidMount() {

        ReactDOM.findDOMNode(this).addEventListener('mousewheel', this.handleOnScroll);
        ReactDOM.findDOMNode(this).addEventListener('DOMMouseScroll', this.handleOnScroll);
        this.state.timeLineMenu = new gsap.TimelineMax();

        const elMenu = findDOMNode(this.refs.toggle);





        // Cursor options with defaults


                // Cursor options with defaults
     
        


        // Basic magnetic


    if(window.innerWidth >= 1024){
        const magnetic = new Magnetic(elMenu,
            {
                y: 0.2, // horizontal delta
                x: 0.2, // vertical delta
                s: 0.2, // speed
                rs: 0.7,
               
            });

        const cursor = new Cursor({
            container: "body", // container to attach
            speed: 0.7, // default speed
            ease: "expo.out", // default ease (gsap)
            visibleTimeout: 300 ,
            color:'#ea6910'// disappear timeout
        });
        
    }
         

     //  this.startAnimationMenu();

    }

    componentWillUnmount() {

        ReactDOM.findDOMNode(this).removeEventListener('mousewheel', this.handleOnScroll);
        ReactDOM.findDOMNode(this).removeEventListener('DOMMouseScroll', this.handleOnScroll);
    }

    handleOnScroll(event) {
        event = event || window.event;
        event.preventDefault();
        event.stopPropagation();
        event.returnValue = false;
    }

    buttonOnClick = () => {
        //this.props.onClick();
        this.setState({
            hamburgerActive: !this.state.hamburgerActive,
        });


        this.props.handleClick();
    }



    /*
    openMenu() {
        this.state.timeLineMenu.reversed(!this.state.timeLineMenu.reversed());

    }
    */
   /*
    startAnimationMenu() {
        this.state.timeLineMenu
            .to('.menu', {
                duration: 0.6,
                ease: "power1.out",
                x: 0
            }).to(".address", {
                opacity: 1,
                x: 0,
                duration: 0.2,

                stagger: {
                    // wrap advanced options in an object
                    each: 0.05,
                    ease: "power1.in"
                }

            }, '<0.05')
            .to(".voice", {
                opacity: 1,
                x: 0,
                duration: 0.2,

                stagger: {
                    // wrap advanced options in an object
                    each: 0.05,
                    ease: "power1.in"
                }

            }, '<0.05')

            .reverse();

    }
    */


    render() {
        const { active, loading } = this.props;



        return (

            <div onClick={this.buttonOnClick} ref="toggle" data-magnetic  data-cursor="-opaque" className="at-menu-toggler" >


                <div className="at-menu-container">
                    <div
                        className={`hamburger-top ${this.props.isOpen ? "active" : ""} ${loading ? "loading" : ""
                            }`}
                    />
                    <div
                        className={`hamburger-center ${this.props.isOpen ? "active" : ""}  ${loading ? "loading" : ""
                            }`}
                    />
                    <div
                        className={`hamburger-bottom ${this.props.isOpen ? "active" : ""}  ${loading ? "loading" : ""
                            }`}
                    />

                </div>
            </div>

        );
    }

}

export default Burger;