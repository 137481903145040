import './ServiceSwiper.css';
import React, { useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import ImageGallery from 'react-image-gallery';


const img1 = require('../../images/service/studio-1.jpg');
const url = "https://images-eu.ssl-images-amazon.com/images/G/29/AmazonMusic/2021/WeeklyBuild/041621/040921_IT_ReDiscover_AntonelloVenditti_PG_GW_CCardM_EG_378x304._SY304_CB654751268_.jpg"
const images = [
    {
        original: 'https://picsum.photos/id/1018/1000/600/',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1015/1000/600/',
        thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1019/1000/600/',
        thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
];

const ServiceSwiper = () => {
    const [numOfSlides, setNumOfSlides] = useState(4);
    const [spaceBetween, setSpaceBeetween] = useState(50);

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setSlidePerView();

        window.addEventListener('resize', () => setSlidePerView());
    }, []);

    const setSlidePerView = () => {
        const windowWidth = window.innerWidth;

        if (windowWidth > 1800) {
            setNumOfSlides(6);
            setSpaceBeetween(300);

        } else if (windowWidth > 1500) {
            setNumOfSlides(4);
            setSpaceBeetween(300);

        }
        else if (windowWidth > 1200) {
            setNumOfSlides(4);
            setSpaceBeetween(300);

        }
        else if (windowWidth > 600) {
            setNumOfSlides(4);
            setSpaceBeetween(50);

        } else {
            setSpaceBeetween(1);

            setNumOfSlides(1);
        }
    }

    const closeLightbox = () => {
        setIsOpen(true);
    };


    return (
        <div className="ServiceSwiper">
            <Gallery fullscreenButton={false}
                shareButton={false}
            >


                <Swiper
                    spaceBetween={spaceBetween}
                    slidesPerView={numOfSlides}
                    loop={true}
                    grabCursor={true}
                    centeredSlides={true}
                >
                    <SwiperSlide>
                        <Item
                            original="https://alfatauristudio.com/studio-images/studio-1.jpg"
                            thumbnail="https://alfatauristudio.com/studio-images/studio-1.jpg"
                            width="1024"
                            height="768"
                        >
                            {({ ref, open }) => (
                                <img className="servizi_pagina_5_row_rect servizi_pagina_5_row_rect_1 serv serv-1" ref={ref} onClick={open} src="https://alfatauristudio.com/studio-images/studio-1.jpg" />
                            )}
                        </Item>
                    </SwiperSlide>
                    <SwiperSlide> <Item
                        original="https://alfatauristudio.com/studio-images/studio-2.jpg"
                        thumbnail="https://alfatauristudio.com/studio-images/studio-2.jpg"
                        width="1024"
                        height="768"
                    >
                        {({ ref, open }) => (
                            <img className="servizi_pagina_5_row_rect servizi_pagina_5_row_rect_2 serv serv-2" ref={ref} onClick={open} src="https://alfatauristudio.com/studio-images/studio-2.jpg" />
                        )}
                    </Item>
                    </SwiperSlide>

                    <SwiperSlide> <Item
                        original="https://alfatauristudio.com/studio-images/studio-4.jpg"
                        thumbnail="https://alfatauristudio.com/studio-images/studio-4.jpg"
                        width="1024"
                        height="768"
                    >
                        {({ ref, open }) => (
                            <img className="servizi_pagina_5_row_rect servizi_pagina_5_row_rect_1 serv serv-4" ref={ref} onClick={open} src="https://alfatauristudio.com/studio-images/studio-4.jpg" />
                        )}
                    </Item>
                    </SwiperSlide>
                    <SwiperSlide> <Item
                        original="https://alfatauristudio.com/studio-images/studio-5.jpg"
                        thumbnail="https://alfatauristudio.com/studio-images/studio-5.jpg"
                        width="1024"
                        height="768"
                    >
                        {({ ref, open }) => (
                            <img className="servizi_pagina_5_row_rect servizi_pagina_5_row_rect_2 serv serv-5" ref={ref} onClick={open} src="https://alfatauristudio.com/studio-images/studio-5.jpg" />
                        )}
                    </Item>
                    </SwiperSlide>
                    <SwiperSlide> <Item
                        original="https://alfatauristudio.com/studio-images/studio-6.jpg"
                        thumbnail="https://alfatauristudio.com/studio-images/studio-6.jpg"
                        width="1024"
                        height="768"
                    >
                        {({ ref, open }) => (
                            <img className="servizi_pagina_5_row_rect servizi_pagina_5_row_rect_1 serv serv-6" ref={ref} onClick={open} src="https://alfatauristudio.com/studio-images/studio-6.jpg" />
                        )}
                    </Item>
                    </SwiperSlide>
                    <SwiperSlide> <Item
                        original="https://alfatauristudio.com/studio-images/studio-7.jpg"
                        thumbnail="https://alfatauristudio.com/studio-images/studio-7.jpg"
                        width="1024"
                        height="768"
                    >
                        {({ ref, open }) => (
                            <img className="servizi_pagina_5_row_rect servizi_pagina_5_row_rect_2 serv serv-7" ref={ref} onClick={open} src="https://alfatauristudio.com/studio-images/studio-7.jpg" />
                        )}
                    </Item>
                    </SwiperSlide>


                </Swiper>
            </Gallery>

        </div>
    );
}

export default ServiceSwiper;