import './servizi2.css';
import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import Menu2 from '../components/menu2/menu2';
import Collab from '../components/Collab/Collab';
import Footer2 from '../components/Footer2/Footer2';
import ReactTooltip from 'react-tooltip';
import ServiceSwiper from '../components/ServiceSwiper/ServiceSwiper';
import ATPartner from '../components/ATPartner';
import AOS from 'aos';

import gsap from 'gsap';
import CSMediumImage from '../components/CaseStudy/CaseStudyRestyle/CSMediumImage/CSMediumImage';

import SplitText from "gsap/SplitText";
import ATLayout from '../components/ATLayout';
import Services from '../components/Services/Services.jsx';
import OurNumbers from '../components/OurNumbers/OurNumbers';
import SEO from '../components/seo';
import Hamburger from '../components/Hamburger/Hamburger';
import CSParallaxElements from '../components/CaseStudy/CaseStudyRestyle/CSParallaxElementsService/CSParallaxElements';
const logotype = require('../images/logotype.svg');

const PARTNERS = [
    {
        name: 'Eurispes',
        link: 'https://eurispes.eu/',
        imageUrl: require('../images/partners/eurispes.png'),
    },
    {
        name: 'Ragioneria Generale dello Stato',
        link: 'https://www.mef.gov.it/index.html',
        imageUrl: require('../images/partners/ragioneria.png'),
    },
    {
        name: 'Parts & Services',
        link: 'https://www.parts-services.it/',
        imageUrl: require('../images/partners/partsservice.png'),
    },
    {
        name: 'Toyota',
        link: 'https://www.toyota.it/',
        imageUrl: require('../images/partners/toyota.png'),
    },
    {
        name: 'OroDolci',
        link: 'https://www.orodolci.it',
        imageUrl: require('../images/partners/orodolci.png'),
    },
    {
        name: 'Molendini',
        link: 'https://www.instagram.com/molendini.ig/?hl=it',
        imageUrl: require('../images/partners/molendini.png'),
    },
    {
        name: 'Sushi-ii',
        link: 'https://www.instagram.com/sushiiilamezia/?hl=it',
        imageUrl: require('../images/partners/sushiii.png'),
    },
    {
        name: 'TEDXLuiss',
        link: 'https://tedxluiss.it/',
        imageUrl: require('../images/partners/tedxluiss.png'),
    },
    {
        name: 'IperDesign',
        link: 'https://www.iperdesign.com/it/',
        imageUrl: require('../images/partners/iperdesign.png'),
    },
    {
        name: 'Quinaryo',
        link: '',
        imageUrl: require('../images/partners/quinaryo.png'),
    },
    {
        name: 'Ex Trappeto',
        link: 'http://www.extrappeto.it/',
        imageUrl: require('../images/partners/extrappeto.png'),
    },
    {
        name: 'Eneide',
        link: 'http://eneide.mx/',
        imageUrl: require('../images/partners/eneide.png'),
    },
    {
        name: 'Roma Tre',
        link: 'http://www.uniroma3.it/',
        imageUrl: require('../images/partners/romatre.svg'),
    },
    {
        name: 'Run For',
        link: 'https://www.facebook.com/niklamezia/',
        imageUrl: require('../images/partners/runfor.svg'),
    },
    {
        name: 'SMASH',
        link: 'http://www.smashofficial.com/',
        imageUrl: require('../images/partners/smash.svg'),
    },
    {
        name: 'Color Fest',
        link: 'https://www.facebook.com/colorfestlamezia/',
        imageUrl: require('../images/partners/colorfest.svg'),
    },
    {
        name: 'Clafin',
        link: 'https://www.clafin.it/',
        imageUrl: require('../images/partners/clafin.svg'),
    },
    {
        name: 'TakeYourCrypto',
        link: 'https://takeyourcrypto.tech/',
        imageUrl: require('../images/partners/tyc.svg'),
    },
    {
        name: 'Mirror',
        link: 'https://www.mirrorme.it/',
        imageUrl: require('../images/partners/mirror.svg'),
    },
    {
        name: 'FRAC Festival',
        link: 'https://www.fracfestival.com',
        imageUrl: require('../images/partners/frac.svg'),
    },
];



const Servizi = () => {
    const [timeLineWords, setTimeLineWords] = useState(gsap.timeline());
    const [menuOpen, setMenuOpen] = useState(false);
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {



        AOS.init();
        AOS.refresh();

        gsap.registerPlugin(SplitText);
        let mySplitText = new SplitText(".title-1", { type: "words,chars" });
        let mySplitText2 = new SplitText(".title-2", { type: "words,chars" });

        let chars = mySplitText.chars;
        let chars2 = mySplitText2.chars;
        gsap.set(".title-1", { perspective: 400 });
        gsap.set(".title-2", { perspective: 400 });

        timeLineWords.pause();

        timeLineWords.from(chars,
            {
                duration: 1.6, opacity: 0, scale: 0.9,
                y: 80, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, "+=0");


        timeLineWords.from(chars2,
            {
                duration: 1.2, opacity: 0, scale: 0.9,
                y: 80, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, "<0.8");

        setTimeout(() => {
            timeLineWords.play();

        }, 1200);

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }, []);


    function handleOnHamburgerClick(event) {
        setMenuOpen(!menuOpen);
    }

    function handleOnOutsideMenuClick(event) {
        setMenuOpen(false);
    }

    return (
        <>
            <ATLayout absolute={true} title="Servizi" isMenuOpen={menuOpen} onOutsideMenuClick={handleOnOutsideMenuClick}>
                <SEO
                    title="Servizi web e di design di Alfatauri Studio"
                    relPath="servizi"
                    description="Forniamo il miglior design e le applicazioni più utili basando la nostra visione sulla raccolta di idee e esigenze, valutate insieme a voi."
                />
                <div className="Servizi">
                    <div className="servizi_pagina_1 animated fadeIn">


                        <div className="servizi_pagina_1_desc">
                            <div className="servizi_pagina_1_title_1 title-1">Progettiamo e sviluppiamo </div>
                            <div className="servizi_pagina_1_title_2 title-1">soluzioni digitali</div>
                            <div className="servizi_pagina_1_subtitle title-2">Non come fanno tutti. <br />Noi non pensiamo solo al tuo business,  <br /> noi pensiamo a come migliorare la vita dei tuoi clienti. <br /> Per far contenti voi e loro. </div>
                            <div className="servizi_pagina_1_subtitle servizi_pagina_1_subtitle_mobile  title-2">Non come fanno tutti.</div>
                            <div className="servizi_pagina_1_subtitle servizi_pagina_1_subtitle_mobile  title-2">Noi non pensiamo solo al tuo</div>
                            <div className="servizi_pagina_1_subtitle servizi_pagina_1_subtitle_mobile  title-2">business, noi pensiamo a come</div>
                            <div className="servizi_pagina_1_subtitle servizi_pagina_1_subtitle_mobile  title-2">migliorare la vita dei tuoi utenti.</div>
                            <div className="servizi_pagina_1_subtitle servizi_pagina_1_subtitle_mobile  title-2">Per far contenti voi e loro.</div>
                        </div>
                    </div>
                    <div className="video-services-2">
                        <CSMediumImage urlImage="   url('https://www.alfatauristudio.com/studio-images/servizi-1.jpeg')"></CSMediumImage>
                    </div>



                    <div className="servizi_pagina great--things">
                        <div className="servizi_pagina_3_title">    Le cose devono funzionare  <br /> per semplificare la vita delle persone. <br /> Per questo ci piace usare solo quello che serve. </div>
                        {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                        <CSParallaxElements
                            servicePage={true}
                            urlImage1Square=""
                            urlImage2Long=""
                            urlImage3Long=" url('https://alfatauristudio.com/assets/images/case-studies/Osservatorio/M_Osservatorio3.jpg')"
                            urlImage4Long="url('https://alfatauristudio.com/assets/images/case-studies/IDM/M_IDM4.jpg')"
                            urlImage6Square="" ></CSParallaxElements>
                    </div>
                    <div className="servizi_pagina services-top">
                        <Services></Services>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12" data-aos="fade-left">
                                <OurNumbers></OurNumbers>
                            </div>
                        </div>
                    </div>


                    <div className="servizi_pagina">
                        <div className="servizi_pagina_3_title">Great things are never</div>
                        <div className="servizi_pagina_3_title">done by one person.</div>

                        <div className="servizi_pagina_5">
                            <ServiceSwiper />

                            <div className="servizi_pagina_5_row servizi_pagina_5_row_compatibilities">
                                <div className="servizi_pagina_5_title">Capabilities </div>
                                <div className="capabilities_container">
                                    <div className="capabilities_container_col">
                                        <div className="capabilities_container_col_title" data-tip="Strategia">Strategy</div>
                                        <div className="capabilities_container_col_text">Research</div>
                                        <div className="capabilities_container_col_text">UI/UX audit</div>
                                        <div className="capabilities_container_col_text">Stakeholder workshops</div>
                                        <div className="capabilities_container_col_text">Product strategy</div>
                                        <div className="capabilities_container_col_text">Innovation consulting</div>
                                    </div>

                                    <div className="capabilities_container_col">
                                        <div className="capabilities_container_col_title" data-tip="Design" >Design</div>
                                        <div className="capabilities_container_col_text">UI/UX design</div>
                                        <div className="capabilities_container_col_text">Brand identity</div>
                                        <div className="capabilities_container_col_text">Websites and mobile apps</div>
                                        <div className="capabilities_container_col_text">Visual design</div>
                                        <div className="capabilities_container_col_text">Prototyping and testing</div>
                                    </div>

                                    <div className="capabilities_container_col">
                                        <div className="capabilities_container_col_title" data-tip="Sviluppo">Development</div>
                                        <div className="capabilities_container_col_text">HTML/CSS/JS</div>
                                        <div className="capabilities_container_col_text">React/Vue/Angular</div>
                                        <div className="capabilities_container_col_text">Backend/API integrations</div>
                                        <div className="capabilities_container_col_text">iOS/Android native apps</div>
                                        <div className="capabilities_container_col_text">Rapid prototyping</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
       

                    <div className="servizi_pagina">
                        <div className="container partners">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4 className="partners__text" data-aos="fade-right">
                                        Abbiamo collaborato con<span style={{ color: 'rgb(232, 83, 29)' }}>:</span>
                                    </h4>
                                </div>
                            </div>

                            <div className="row">
                                {PARTNERS.map(p => (
                                    <div className="col-6 col-lg-3" key={p.name}>
                                        <ATPartner name={p.name} link={p.link} imageUrl={p.imageUrl} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Footer2 />
                    <Hamburger open={menuOpen} onClick={handleOnHamburgerClick} />

                    <ReactTooltip />
                </div>
            </ATLayout>

        </>
    );
}

export default Servizi;