import React from 'react'

import './ATPartner.css'

const ATPartner = (props) => {
	return (
		<a className="at-partner" href={props.link} target="_blank" rel="noopener noreferrer">
			<img src={props.imageUrl} alt={props.name + " logo"} data-aos="zoom-in" />
		</a>
	);
}

export default ATPartner;